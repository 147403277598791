var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "派单管理", name: "first" } },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c("span", [_vm._v("状态：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.animeDat(_vm.status1)
                    },
                  },
                  model: {
                    value: _vm.status1,
                    callback: function ($$v) {
                      _vm.status1 = $$v
                    },
                    expression: "status1",
                  },
                },
                _vm._l(_vm.statesnum, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c("span", [_vm._v("服务类型：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.animeDat()
                    },
                  },
                  model: {
                    value: _vm.taskTypeT,
                    callback: function ($$v) {
                      _vm.taskTypeT = $$v
                    },
                    expression: "taskTypeT",
                  },
                },
                _vm._l(_vm.parentIdList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c("span", [_vm._v("服务分类：")]),
              _c("el-cascader", {
                staticStyle: { width: "150px" },
                attrs: {
                  options: _vm.homeData1,
                  placeholder: "请选择服务分类",
                  "show-all-levels": false,
                  props: {
                    expandTrigger: "hover",
                    value: "classifyId",
                    label: "classifyName",
                  },
                  filterable: "",
                },
                on: { change: _vm.handleChange2 },
                model: {
                  value: _vm.gameIdsVal,
                  callback: function ($$v) {
                    _vm.gameIdsVal = $$v
                  },
                  expression: "gameIdsVal",
                },
              }),
              _vm._v("    "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                  },
                },
                [
                  _c("span", [_vm._v("手机号：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone1,
                      callback: function ($$v) {
                        _vm.phone1 = $$v
                      },
                      expression: "phone1",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                  },
                },
                [
                  _c("span", [_vm._v("内容：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入内容" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.content1,
                      callback: function ($$v) {
                        _vm.content1 = $$v
                      },
                      expression: "content1",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select1 },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans },
                },
                [_vm._v("重置 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "0 0 20px 20px" },
                  attrs: {
                    disabled: !_vm.isAuth("universalMission:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.classifyStair(1)
                    },
                  },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableData.list },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "userName", label: "发布用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.userId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "发布用户图像", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar == null
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/img/avatar.png"),
                                alt: "",
                                width: "40",
                                height: "40",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: scope.row.avatar,
                                alt: "",
                                width: "40",
                                height: "40",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "helpTakeUserName",
                  label: "接单用户",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.helpTake
                          ? _c("span", [
                              scope.row.helpTake.user
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#4f9dec",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updates(
                                            scope.row.helpTake.user.userId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.helpTake.user
                                              ? scope.row.helpTake.user.userName
                                              : "未绑定"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _c("span", [_vm._v(" - ")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "taskType", label: "服务类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.taskType == 1
                          ? _c("span", [_vm._v("悬赏任务")])
                          : _vm._e(),
                        scope.row.taskType == 2
                          ? _c("span", [_vm._v("报价任务")])
                          : _vm._e(),
                        scope.row.taskType == 3
                          ? _c("span", [_vm._v("一口价任务")])
                          : _vm._e(),
                        scope.row.taskType == 4
                          ? _c("span", [_vm._v("师傅任务")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "serviceName", label: "服务分类" },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "内容", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { prop: "image", label: "服务要求图片", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.image
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.image.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "300px",
                                              height: "auto",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNo", label: "订单号", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "服务人姓名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "服务人电话" },
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "发布价格" },
              }),
              _c("el-table-column", {
                attrs: { prop: "commission", label: "接单价格" },
              }),
              _c("el-table-column", {
                attrs: { prop: "helpTake.zhiMoney", label: "一级佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.helpTake
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(
                                      scope.row.helpTake.zhiUserId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.helpTake.zhiMoney
                                        ? scope.row.helpTake.zhiMoney
                                        : "0"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "helpTake.feiMoney", label: "二级佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.helpTake
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(
                                      scope.row.helpTake.feiUserId
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.helpTake.feiMoney
                                        ? scope.row.helpTake.feiMoney
                                        : "0"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "helpTake.pingMoney", label: "平台佣金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.helpTake
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.helpTake.pingMoney
                                        ? scope.row.helpTake.pingMoney
                                        : "0"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "maxPeople", label: "报价人数" },
              }),
              _c("el-table-column", {
                attrs: { prop: "code", label: "确认码" },
              }),
              _c("el-table-column", {
                attrs: { prop: "province", label: "省" },
              }),
              _c("el-table-column", { attrs: { prop: "city", label: "市" } }),
              _c("el-table-column", {
                attrs: { prop: "district", label: "区" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "detailsAddress",
                  label: "服务人详细地址",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "longitude", label: "经度", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "latitude", label: "纬度", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "helpTake.startTaskAddress",
                  label: "服务开始地址",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "helpTake.endTaskAddress",
                  label: "服务结束地址",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "helpTake.startTime", label: "服务开始时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "helpTake.endTime", label: "服务结束时间" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "helpTake.startTaskImg",
                  label: "服务开始前图片",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.helpTake && scope.row.helpTake.startTaskImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.helpTake.startTaskImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "300px",
                                              height: "auto",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "helpTake.endTaskImg",
                  label: "服务结束后图片",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.helpTake && scope.row.helpTake.endTaskImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                },
                              },
                              _vm._l(
                                scope.row.helpTake.endTaskImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "300px",
                                              height: "auto",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryTime",
                  label: "预约时间",
                  fixed: "right",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: { color: "red", "font-weight": "600" },
                          },
                          [_vm._v(_vm._s(scope.row.deliveryTime))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              background: "#fff",
                              border: "none",
                            },
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.rechargenone(scope.row, 2)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "订单备注", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.remarks ? scope.row.remarks : "未填写"
                            )
                          ),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              background: "#fff",
                              border: "none",
                            },
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.rechargenone(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  prop: "status",
                  label: "状态",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 0
                          ? _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("待支付"),
                            ])
                          : _vm._e(),
                        scope.row.status === 1
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待接单"),
                            ])
                          : _vm._e(),
                        scope.row.status === 2
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("待服务"),
                            ])
                          : _vm._e(),
                        scope.row.status === 3
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("进行中"),
                            ])
                          : _vm._e(),
                        scope.row.status === 4
                          ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                              _vm._v("已完成"),
                            ])
                          : _vm._e(),
                        scope.row.status === 5
                          ? _c("span", [_vm._v("已取消")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "3px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.bufeiBtn(scope.row)
                              },
                            },
                          },
                          [_vm._v("补费列表 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "3px" },
                            attrs: {
                              size: "mini",
                              type: "warning",
                              disabled:
                                !_vm.isAuth("universalMission:update") ||
                                scope.row.status === 5 ||
                                scope.row.status === 4,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.classifyQx(scope.row)
                              },
                            },
                          },
                          [_vm._v("取消派单 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "3px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled:
                                !_vm.isAuth("universalMission:update") ||
                                scope.row.status === 5 ||
                                scope.row.status === 4 ||
                                scope.row.status === 1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.classifySd(scope.row)
                              },
                            },
                          },
                          [_vm._v("确认完成 ")]
                        ),
                        scope.row.taskType == 2
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "3px" },
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.classifyBj(scope.row)
                                  },
                                },
                              },
                              [_vm._v("报价列表 ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "3px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.plCompile(scope.row)
                              },
                            },
                          },
                          [_vm._v("评论列表 ")]
                        ),
                        scope.row.status === 1 && scope.row.taskType != 2
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "3px" },
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  plain: "",
                                  disabled: !_vm.isAuth(
                                    "universalMission:update"
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.zhuandan(scope.row)
                                  },
                                },
                              },
                              [_vm._v("派单")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "3px" },
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("universalMission:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteStair(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 本页累计总金额统计：" +
                  _vm._s(_vm.totalMoney.toFixed(2)) +
                  "元; "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 一级佣金统计：" + _vm._s(_vm.totalMoneyS.toFixed(2)) + "元; "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 二级佣金统计：" + _vm._s(_vm.totalMoneyTy.toFixed(2)) + "元; "
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                " 平台佣金统计：" + _vm._s(_vm.totalMoneyP.toFixed(2)) + "元"
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.tableData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "服务拒绝",
            visible: _vm.dialogFormVisible7,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible7 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("拒绝理由：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "text", placeholder: "请输入拒绝理由" },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible7 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.refuseto()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titles,
            visible: _vm.dialogFormVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("发布人：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请选择发布人" },
                on: { focus: _vm.userselect },
                model: {
                  value: _vm.nickName1,
                  callback: function ($$v) {
                    _vm.nickName1 = $$v
                  },
                  expression: "nickName1",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("服务类型：")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "50%" },
                  model: {
                    value: _vm.parentId,
                    callback: function ($$v) {
                      _vm.parentId = $$v
                    },
                    expression: "parentId",
                  },
                },
                _vm._l(_vm.parentIdList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("    "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("服务分类：")]
              ),
              _c("el-cascader", {
                staticStyle: { width: "50%" },
                attrs: {
                  options: _vm.homeData1,
                  placeholder: _vm.fixedClassifyIdTitle,
                  "show-all-levels": false,
                  props: {
                    expandTrigger: "hover",
                    value: "classifyId",
                    label: "classifyName",
                  },
                  filterable: "",
                },
                on: { change: _vm.handleChange1 },
                model: {
                  value: _vm.fixedClassifyIds,
                  callback: function ($$v) {
                    _vm.fixedClassifyIds = $$v
                  },
                  expression: "fixedClassifyIds",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("上门时间：")]
              ),
              _c("el-date-picker", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "datetime",
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm",
                  placeholder: "选择上门时间",
                },
                model: {
                  value: _vm.deliveryTime,
                  callback: function ($$v) {
                    _vm.deliveryTime = $$v
                  },
                  expression: "deliveryTime",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("具体要求：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "请输入具体要求",
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px", display: "flex" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("要求图片：")]
              ),
              _vm._l(_vm.image, function (item, index) {
                return _c("div", { key: index, staticClass: "imgs" }, [
                  _c("img", {
                    staticClass: "images",
                    attrs: {
                      width: "100%",
                      height: "100%",
                      src: item,
                      alt: "",
                    },
                  }),
                  _c("span", { staticClass: "dels" }, [
                    _c("i", {
                      staticClass: "el-icon-delete",
                      on: {
                        click: function ($event) {
                          return _vm.clear(index)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              _c(
                "div",
                { staticClass: "imgs", staticStyle: { width: "50%" } },
                [
                  _c(
                    "custom-upload",
                    {
                      attrs: {
                        action: _vm.$http.adornUrl("oss/upload"),
                        "list-type": "picture-card",
                        "show-file-list": false,
                        "on-success": _vm.handleUploadSuccess,
                        "on-progress": _vm.onprogress1,
                      },
                    },
                    [
                      _vm.percentage1 > 0 && _vm.percentage1 < 100
                        ? _c("el-progress", {
                            attrs: {
                              type: "circle",
                              percentage: _vm.percentage1,
                            },
                          })
                        : _c("i", { staticClass: "el-icon-plus" }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm.parentId != 2
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("发布价格：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入发布价格" },
                    model: {
                      value: _vm.commission,
                      callback: function ($$v) {
                        _vm.commission = $$v
                      },
                      expression: "commission",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.parentId == 2
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("期望价格：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入期望价格" },
                    model: {
                      value: _vm.commission,
                      callback: function ($$v) {
                        _vm.commission = $$v
                      },
                      expression: "commission",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.parentId == 2
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("报价人数：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入报价人数" },
                    model: {
                      value: _vm.maxPeople,
                      callback: function ($$v) {
                        _vm.maxPeople = $$v
                      },
                      expression: "maxPeople",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("姓名：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "text", placeholder: "请输入姓名" },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("手机号：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "text", placeholder: "请输入手机号" },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("服务地区：")]
              ),
              _c("el-cascader", {
                ref: "cascaderAddr",
                staticStyle: { width: "50%" },
                attrs: {
                  size: "large",
                  options: _vm.options,
                  placeholder: _vm.storeAddre,
                  value: _vm.storeAddre,
                },
                on: { change: _vm.handleChange55 },
                model: {
                  value: _vm.storeAddress,
                  callback: function ($$v) {
                    _vm.storeAddress = $$v
                  },
                  expression: "storeAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("详细地址：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入详细地址" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.select.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.detailsAddress,
                  callback: function ($$v) {
                    _vm.detailsAddress = $$v
                  },
                  expression: "detailsAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("纬度：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入纬度" },
                model: {
                  value: _vm.latitude,
                  callback: function ($$v) {
                    _vm.latitude = $$v
                  },
                  expression: "latitude",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("经度：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { placeholder: "请输入经度" },
                model: {
                  value: _vm.longitude,
                  callback: function ($$v) {
                    _vm.longitude = $$v
                  },
                  expression: "longitude",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c("div", {
              staticStyle: {
                width: "80%",
                height: "500px",
                "margin-left": "10%",
              },
              attrs: { id: "container1" },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.StairNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.dialogFormVisible3,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible3 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "2% 0", display: "inline-block" } },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入手机号" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.userclick.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.phone1,
                  callback: function ($$v) {
                    _vm.phone1 = $$v
                  },
                  expression: "phone1",
                },
              }),
              _vm._v("     "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入昵称" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.userclick.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.nickName2,
                  callback: function ($$v) {
                    _vm.nickName2 = $$v
                  },
                  expression: "nickName2",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.userclick },
                },
                [_vm._v(" 查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.userclose },
                },
                [_vm._v(" 重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading4,
                  expression: "tableDataLoading4",
                },
              ],
              attrs: { width: "780px", data: _vm.userIds.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "nickName",
                  width: "220",
                  label: "昵称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(
                            _vm._s(
                              scope.row.userName ? scope.row.userName : "未设置"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", width: "220", label: "手机号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.phone ? scope.row.phone : "未绑定")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", width: "220", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.confirm(scope.row)
                              },
                            },
                          },
                          [_vm._v("确定 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 15, 20],
                  "page-size": _vm.size1,
                  "current-page": _vm.page1,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.userIds.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange2,
                  "current-change": _vm.handleCurrentChange2,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "修改", visible: _vm.dialogFormVisible1, center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible1 = $event
            },
          },
        },
        [
          _vm.types == 1
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("备注：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.conters,
                      callback: function ($$v) {
                        _vm.conters = $$v
                      },
                      expression: "conters",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.types == 2
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("预约时间：")]
                  ),
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择日期时间",
                      format: "yyyy-MM-dd HH:mm",
                      "value-format": "yyyy-MM-dd HH:mm",
                    },
                    model: {
                      value: _vm.conters,
                      callback: function ($$v) {
                        _vm.conters = $$v
                      },
                      expression: "conters",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.StairNoticeTo1()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报价列表",
            visible: _vm.dialogFormVisibleBj,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleBj = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading4,
                  expression: "tableDataLoading4",
                },
              ],
              attrs: { width: "780px", data: _vm.baojiaList },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "biddingId",
                  width: "80",
                  label: "编号",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f56c6c",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.userId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.userName
                                  ? scope.row.userName
                                  : "未设置"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.phone ? scope.row.phone : "未绑定")
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "offerMoney", label: "报价金额" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "评论列表",
            visible: _vm.dialogFormVisible10,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible10 = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingPl,
                  expression: "tableDataLoadingPl",
                },
              ],
              attrs: { data: _vm.pinglunData.records },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "evaluateId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "评论用户", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { color: "#4f9dec", cursor: "pointer" },
                          domProps: { textContent: _vm._s(scope.row.userName) },
                          on: {
                            click: function ($event) {
                              return _vm.updatesvideo(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "evaluateMessage", label: "评论内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "evaluateImg", label: "评论图片", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.evaluateImg
                          ? _c(
                              "div",
                              _vm._l(
                                scope.row.evaluateImg.split(","),
                                function (item, index) {
                                  return _c(
                                    "span",
                                    { key: index },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top-start",
                                            title: "",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              margin: "3px",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              src: item,
                                              alt: "",
                                            },
                                            slot: "reference",
                                          }),
                                          _c("img", {
                                            staticStyle: {
                                              width: "200px",
                                              height: "200px",
                                            },
                                            attrs: { src: item, alt: "" },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "satisfactionFlag",
                  label: "满意度",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.satisfactionFlag == 1
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("非常差")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 2
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "orange",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("差")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 3
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#999",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("一般")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 4
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("满意")]
                            )
                          : _vm._e(),
                        scope.row.satisfactionFlag == 5
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("非常满意")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "评论时间", width: "180" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  prop: "id",
                  width: "100",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("locality:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.pinglundelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.size2,
                  "current-page": _vm.page2,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.pinglunData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange3,
                  "current-change": _vm.handleCurrentChange3,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "补费列表",
            visible: _vm.dialogFormVisibleBf,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleBf = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading2,
                  expression: "tableDataLoading2",
                },
              ],
              attrs: { data: _vm.userData },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "recordId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "用户昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { color: "#4f9dec", cursor: "pointer" },
                          domProps: { textContent: _vm._s(scope.row.userName) },
                          on: {
                            click: function ($event) {
                              return _vm.updates(scope.row.userId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payMoney", label: "补费金额" },
              }),
              _c("el-table-column", {
                attrs: { prop: "money", label: "实际到账金额" },
              }),
              _c("el-table-column", {
                attrs: { prop: "rideName", label: "接收用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: { color: "#4f9dec", cursor: "pointer" },
                          domProps: { textContent: _vm._s(scope.row.rideName) },
                          on: {
                            click: function ($event) {
                              return _vm.updates(scope.row.rideId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "payWay", label: "支付方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payWay == 0
                          ? _c("span", [_vm._v("余额")])
                          : _vm._e(),
                        scope.row.payWay == 1
                          ? _c("span", [_vm._v("微信")])
                          : _vm._e(),
                        scope.row.payWay == 2
                          ? _c("span", [_vm._v("支付宝")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", [_vm._v("已支付")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("已退回")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "师傅列表",
            visible: _vm.dialogFormVisibleSf,
            center: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleSf = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("手机号:")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入手机号" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.selectU.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.phoneU,
                  callback: function ($$v) {
                    _vm.phoneU = $$v
                  },
                  expression: "phoneU",
                },
              }),
            ],
            1
          ),
          _vm._v("   "),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("昵称:")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入昵称" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.selectU.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.campus,
                  callback: function ($$v) {
                    _vm.campus = $$v
                  },
                  expression: "campus",
                },
              }),
            ],
            1
          ),
          _vm._v("   "),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("商家名称:")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入商家名称" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.selectU.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.sexU,
                  callback: function ($$v) {
                    _vm.sexU = $$v
                  },
                  expression: "sexU",
                },
              }),
            ],
            1
          ),
          _vm._v("   "),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("邀请码:")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入邀请码" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.selectU.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.invitationCode,
                  callback: function ($$v) {
                    _vm.invitationCode = $$v
                  },
                  expression: "invitationCode",
                },
              }),
            ],
            1
          ),
          _vm._v("   "),
          _c(
            "div",
            { staticStyle: { margin: "5px", display: "inline-block" } },
            [
              _c("span", [_vm._v("邀请人邀请码:")]),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入邀请人邀请码" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.selectU.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.inviterCode,
                  callback: function ($$v) {
                    _vm.inviterCode = $$v
                  },
                  expression: "inviterCode",
                },
              }),
            ],
            1
          ),
          _vm._v("   "),
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.selectU },
                },
                [_vm._v("查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleansU },
                },
                [_vm._v("重置 ")]
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClickU },
              model: {
                value: _vm.activeNameU,
                callback: function ($$v) {
                  _vm.activeNameU = $$v
                },
                expression: "activeNameU",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "商家用户", name: "first" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableDataLoadingU,
                          expression: "tableDataLoadingU",
                        },
                      ],
                      attrs: { data: _vm.userListData.list },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "userId",
                          label: "编号",
                          width: "80",
                          fixed: "left",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "userName",
                          label: "昵称",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#f56c6c" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.userName
                                          ? scope.row.userName
                                          : "未绑定"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "isAuthentication", label: "性别" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.sex == 1
                                  ? _c("span", [_vm._v("男")])
                                  : scope.row.sex == 2
                                  ? _c("span", [_vm._v("女")])
                                  : _c("span", [_vm._v("未设置")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "图像" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.avatar == null
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/avatar.png"),
                                        alt: "",
                                        width: "40",
                                        height: "40",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: scope.row.avatar,
                                        alt: "",
                                        width: "40",
                                        height: "40",
                                      },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "phone", label: "手机号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updates(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.phone
                                            ? scope.row.phone
                                            : "未绑定"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "phone", label: "用户类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isAuthentication == 1
                                  ? _c("span", [_vm._v("个人认证")])
                                  : scope.row.isAuthentication == 2
                                  ? _c("span", [_vm._v("企业认证")])
                                  : scope.row.isAuthentication == 3
                                  ? _c("span", [_vm._v("个人企业认证")])
                                  : _c("span", [_vm._v("普通用户")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "invitationCode", label: "邀请码" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "邀请人邀请码" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.inviterCode
                                          ? scope.row.inviterCode
                                          : "未绑定"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isAuthentication == 1
                                  ? _c("span", [_vm._v("是")])
                                  : _c("span", [_vm._v("否")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiFuBaoName
                                        ? scope.row.zhiFuBaoName
                                        : "未绑定"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiFuBao
                                        ? scope.row.zhiFuBao
                                        : "未绑定"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rate",
                          label: "商家佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.rate ? scope.row.rate : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "zhiRate",
                          label: "一级佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiRate
                                        ? scope.row.zhiRate
                                        : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "feiRate",
                          label: "二级佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.feiRate
                                        ? scope.row.feiRate
                                        : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "创建时间",
                          width: "160",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          prop: "id",
                          width: "100",
                          fixed: "right",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.userBtns(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("确认 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-sizes": [10, 20, 30, 40],
                          "page-size": _vm.limit1,
                          "current-page": _vm.page1,
                          layout: "total,sizes, prev, pager, next,jumper",
                          total: _vm.userListData.totalCount,
                        },
                        on: {
                          "size-change": _vm.handleSizeChangeU,
                          "current-change": _vm.handleCurrentChangeU,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "师傅用户", name: "two" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableDataLoadingU,
                          expression: "tableDataLoadingU",
                        },
                      ],
                      attrs: { data: _vm.userListData.list },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "userId",
                          label: "编号",
                          width: "80",
                          fixed: "left",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "userName",
                          label: "昵称",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#f56c6c" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.userName
                                          ? scope.row.userName
                                          : "未绑定"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "isAuthentication", label: "性别" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.sex == 1
                                  ? _c("span", [_vm._v("男")])
                                  : scope.row.sex == 2
                                  ? _c("span", [_vm._v("女")])
                                  : _c("span", [_vm._v("未设置")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "图像" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.avatar == null
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/avatar.png"),
                                        alt: "",
                                        width: "40",
                                        height: "40",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: scope.row.avatar,
                                        alt: "",
                                        width: "40",
                                        height: "40",
                                      },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "phone", label: "手机号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updates(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.phone
                                            ? scope.row.phone
                                            : "未绑定"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "phone", label: "用户类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isAuthentication == 1
                                  ? _c("span", [_vm._v("个人认证")])
                                  : scope.row.isAuthentication == 2
                                  ? _c("span", [_vm._v("企业认证")])
                                  : scope.row.isAuthentication == 3
                                  ? _c("span", [_vm._v("个人企业认证")])
                                  : _c("span", [_vm._v("普通用户")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "invitationCode", label: "邀请码" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "邀请人邀请码" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.inviterCode
                                          ? scope.row.inviterCode
                                          : "未绑定"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isAuthentication == 1
                                  ? _c("span", [_vm._v("是")])
                                  : _c("span", [_vm._v("否")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiFuBaoName
                                        ? scope.row.zhiFuBaoName
                                        : "未绑定"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiFuBao
                                        ? scope.row.zhiFuBao
                                        : "未绑定"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rate",
                          label: "商家佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.rate ? scope.row.rate : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "zhiRate",
                          label: "一级佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiRate
                                        ? scope.row.zhiRate
                                        : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "feiRate",
                          label: "二级佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.feiRate
                                        ? scope.row.feiRate
                                        : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "创建时间",
                          width: "160",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          prop: "id",
                          width: "100",
                          fixed: "right",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.userBtns(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("确认 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-sizes": [10, 20, 30, 40],
                          "page-size": _vm.limit1,
                          "current-page": _vm.page1,
                          layout: "total,sizes, prev, pager, next,jumper",
                          total: _vm.userListData.totalCount,
                        },
                        on: {
                          "size-change": _vm.handleSizeChangeU,
                          "current-change": _vm.handleCurrentChangeU,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "员工用户", name: "three" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableDataLoadingU,
                          expression: "tableDataLoadingU",
                        },
                      ],
                      attrs: { data: _vm.userListData.list },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "userId",
                          label: "编号",
                          width: "80",
                          fixed: "left",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "",
                          prop: "userName",
                          label: "昵称",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#f56c6c" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.userName
                                          ? scope.row.userName
                                          : "未绑定"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "isAuthentication", label: "性别" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.sex == 1
                                  ? _c("span", [_vm._v("男")])
                                  : scope.row.sex == 2
                                  ? _c("span", [_vm._v("女")])
                                  : _c("span", [_vm._v("未设置")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "图像" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.avatar == null
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/avatar.png"),
                                        alt: "",
                                        width: "40",
                                        height: "40",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: scope.row.avatar,
                                        alt: "",
                                        width: "40",
                                        height: "40",
                                      },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "phone", label: "手机号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updates(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.phone
                                            ? scope.row.phone
                                            : "未绑定"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "phone", label: "用户类型" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isAuthentication == 1
                                  ? _c("span", [_vm._v("个人认证")])
                                  : scope.row.isAuthentication == 2
                                  ? _c("span", [_vm._v("企业认证")])
                                  : scope.row.isAuthentication == 3
                                  ? _c("span", [_vm._v("个人企业认证")])
                                  : _c("span", [_vm._v("普通用户")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "invitationCode", label: "邀请码" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "邀请人邀请码" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.inviterCode
                                          ? scope.row.inviterCode
                                          : "未绑定"
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.isAuthentication == 1
                                  ? _c("span", [_vm._v("是")])
                                  : _c("span", [_vm._v("否")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiFuBaoName
                                        ? scope.row.zhiFuBaoName
                                        : "未绑定"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiFuBao
                                        ? scope.row.zhiFuBao
                                        : "未绑定"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rate",
                          label: "商家佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.rate ? scope.row.rate : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "zhiRate",
                          label: "一级佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.zhiRate
                                        ? scope.row.zhiRate
                                        : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "feiRate",
                          label: "二级佣金比例",
                          width: "150",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.feiRate
                                        ? scope.row.feiRate
                                        : "0"
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "创建时间",
                          width: "160",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          prop: "id",
                          width: "100",
                          fixed: "right",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.userBtns(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("确认 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-sizes": [10, 20, 30, 40],
                          "page-size": _vm.limit1,
                          "current-page": _vm.page1,
                          layout: "total,sizes, prev, pager, next,jumper",
                          total: _vm.userListData.totalCount,
                        },
                        on: {
                          "size-change": _vm.handleSizeChangeU,
                          "current-change": _vm.handleCurrentChangeU,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
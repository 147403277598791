/**
 * 是否为开发环境
 * @returns {Boolean}
 */
export const isDev = () => {
  return process.env.DEV;
};

/**
 * 是否为生产环境
 * @returns {Boolean}
 */
export const isProd = () => {
  return process.env.PROD;
};

/**
 * 获取环境
 * @returns {String}
 */
export const getNodeEnv = () => {
  return process.env.NODE_ENV;
};

/**
 * 获取request baseUrl
 * @returns {String}
 */
export const BASEURL = process.env.VUE_APP_BASE_URL;
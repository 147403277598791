import { render, staticRenderFns } from "./main-sidebar.vue?vue&type=template&id=7553f462"
import script from "./main-sidebar.vue?vue&type=script&lang=js"
export * from "./main-sidebar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/prodctyun-yugao-maintenance-manage-consumer-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7553f462')) {
      api.createRecord('7553f462', component.options)
    } else {
      api.reload('7553f462', component.options)
    }
    module.hot.accept("./main-sidebar.vue?vue&type=template&id=7553f462", function () {
      api.rerender('7553f462', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/main-sidebar.vue"
export default component.exports